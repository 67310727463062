<!--
 * @Author: filename
 * @Description: 家庭成员 - 父母
-->

<template>
    <div class="mt-20">
        <Container title="父母" padding="15px">
            <el-row  v-for="(data, i) in data" :key="i">
                <el-col :span="24" v-if="i>0">
                    <el-divider />
                </el-col>
                <el-col :span="12">
                    <span>{{ i===0?'父亲':'母亲' }}姓名：</span>
                    <span>
                        {{ data.username }}
                        <i v-if="data.deceased">(<i style="color: red;">已故</i>)</i>
                    </span>
                </el-col>
                <template v-if="data.deceased">
                    <el-col :span="12">
                        <span>出生日期：</span>
                        <span>{{ data.birthday }}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>出生城市：</span>
                        <span>{{ data.birth_place }}</span>
                    </el-col>
                </template>

                <template v-if="!data.deceased">
                    <el-col :span="12">
                        <span>婚姻状况：</span>
                        <span>{{ data.married }}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>出生日期：</span>
                        <span>{{ data.birthday }}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>出生城市：</span>
                        <span>{{ data.birth_place }}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>是否在职：</span>
                        <span>{{ data.working_state }}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>职位名称：</span>
                        <span>{{ data.profession }}</span>
                    </el-col>
                    <!-- <el-col :span="24">
                        <span>国籍/地区：</span>
                        <span>{{ data.nationality }}</span>
                    </el-col> -->
                    <el-col :span="12">
                        <span>是否办理过澳门身份证：</span>
                        <span class="red">{{ data.hk_id.has }}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>澳门身份证：</span>
                        <span>{{ data.hk_id.number || '--' }}</span>
                    </el-col>
                </template>
            </el-row>
        </Container>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {

        }
    }
}
</script>

<style lang="less" scoped>
.red {
    color: red;
}
</style>
