import { render, staticRenderFns } from "./Residences.vue?vue&type=template&id=6a250eda&scoped=true&"
import script from "./Residences.vue?vue&type=script&lang=js&"
export * from "./Residences.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a250eda",
  null
  
)

export default component.exports