<!--
 * @Author: Jacob.Zhang
 * @Description: 个人信息表 - 主申请人信息
-->
<template>
    <div class="details-module">
        <Container title="主申请人" padding="15px">
            <!-- 主申请人信息 -->
            <el-row :gutter="15">
                <el-col :span="12">
                    <span>主申姓名：</span>
                    <span>{{ applicant.username }}</span>
                </el-col>
                <el-col :span="12">
                    <span>曾用名：</span>
                    <span>{{ applicant.used_name }}</span>
                </el-col>
                <el-col :span="12">
                    <span>婚前姓氏：</span>
                    <span>{{ applicant.surname || '-' }}</span>
                </el-col>
                <el-col :span="12">
                    <span>姓名拼音：</span>
                    <span>{{ applicant.username_pinyin }}</span>
                </el-col>
                <el-col :span="12">
                    <span>性别：</span>
                    <span>{{ applicant.sex }}</span>
                </el-col>
                <el-col :span="12">
                    <span>出生日期：</span>
                    <span>{{ applicant.birthday }}</span>
                </el-col>
                <el-col :span="12">
                    <span>出生城市：</span>
                    <span>{{ applicant.birth_place }}</span>
                </el-col>
                <el-col :span="12">
                    <span>手机号：</span>
                    <span>{{ applicant.mobile }}</span>
                </el-col>
                <el-col :span="12">
                    <span>邮箱：</span>
                    <span>{{ applicant.email }}</span>
                </el-col>
                <el-col :span="12">
                    <span>婚姻状况：</span>
                    <span>{{ applicant.married }}</span>
                </el-col>
                <el-col :span="12">
                    <span>国籍：</span>
                    <span>{{ applicant.nationality }}</span>
                </el-col>
                <el-row>
                    <el-col :span="inChina ? 24 : 12" id="live_address">
                        <span>现居住地址：</span>
                        <span>{{ applicant.live_address }}</span>
                    </el-col>
                    <el-col :span="12" v-if="!inChina">
                        <span>是否在海外居住1年及以上: </span>
                        <span>{{ applicant.is_live_oversea_year === 1 ? '是' : '否' }}</span>
                    </el-col>
                </el-row>
                <el-col :span="12">
                    <span>是否办理过澳门身份证：</span>
                    <!-- <span v-if="applicant.hk_id" :class="{ 'text-red': applicant.hk_id.has === '是' }">{{ applicant.hk_id.has }}</span> -->
                    <span v-if="applicant.hk_id" class="text-red">{{ applicant.hk_id.has }}</span>
                </el-col>
                <el-col :span="12">
                    <span>澳门身份证号码：</span>
                    <span v-if="applicant.hk_id">{{ applicant.hk_id.has === '是' ? applicant.hk_id.number : '-' }}</span>
                </el-col>
            </el-row>
            <!-- 证件 -->
            <template v-for="(item, index) in applicant.certificates">
                <el-row :key="index" :gutter="15">
                    <el-divider />
                    <el-col :span="24">
                        <span>证件类别：</span>
                        <span>{{ item.type }}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>证件号码：</span>
                        <span>{{ item.number }}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>签发地：</span>
                        <span>{{ item.issue_at }}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>签发日期：</span>
                        <span>{{ item.issue_date_start_at }}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>届满日期：</span>
                        <span>{{ item.issue_date_end_at }}</span>
                    </el-col>
                </el-row>
            </template>
        </Container>
    </div>
</template>

<script>
export default {
    props: {
        applicant: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    computed: {
        inChina() {
            return this.applicant.address.country.includes('中国')
        }
    }
}
</script>

<style lang="less" scoped>
#live_address {
    align-items: normal;
    padding-right: 15px;
}
</style>
