<!--
 * @Author: zhai
 * @Description: 评分
-->
<template>
    <div v-loading="isLoading">
        <div v-if="Object.keys(data).length > 0" class="con">
            <div class="item head">
                <div>题目</div>
                <div>结果</div>
                <div>得分</div>
            </div>
            <div class="item">
                <div class="tit">年龄得分(最高30分)</div>
            </div>
            <div class="item answer">
                <div class="w70 ite1">
                    <div>1）18-39(30分)</div>
                    <div>2）40-44(20分)</div>
                    <div>3）45-50(15分)</div>
                    <div>4）51+(0分)</div>
                </div>
                <div class="w15 ite2">{{data.age_score.result}}</div>
                <div class="w15 ite3">{{data.age_score.score}}分</div>
            </div>
            <div class="item">
                <div class="tit">学历/专业资格(最高70分)</div>
            </div>
            <div class="item answer">
                <div class="w70 ite1">
                    <div>1）博士学位/2个或以上硕士学位(40分)</div>
                    <div>2）硕士学位/2个或以上学士学位(20分)</div>
                    <div class="overs">
                        3）学士学位/由国家或国际认可或著名的专业团队颁授，证明持有人具有极高水平的专业知识或专业技能的专业资格(10分)
                    </div>
                    <div>
                        4）如学士或以上程度的学位是由国际认可的著名院校颁授，可额外获取分数(30分)
                    </div>
                </div>
                <div class="w15">
                    <div class="edca">{{data.education_score_a.result}}</div>
                    <div class="edca2">{{data.education_score_b.result}}</div>
                </div>
                <div class="w15">
                    <div class="edca-num1">{{data.education_score_a.score}}分</div>
                    <div class="edca-num2">{{data.education_score_b.score}}分</div>
                </div>
            </div>
            <div class="item">
                <div class="tit">工作经验(最高75分)</div>
            </div>
            <div class="item answer">
                <div class="w70 ite1">
                    <div>1）获得最低学位证以后，拥有不超过2年工作经验(0分)</div>
                    <div>
                        2）不少于2年相当于学位程度或专家水平的工作经验(5分)
                    </div>
                    <div>
                        3）不少于5年相当于学位程度或专家水平的工作经验(15分)
                    </div>
                    <div>
                        4）不少于5年相当于学位程度或专家水平的工作经验，当中最少2年担任高级职位(30分)
                    </div>
                    <div>
                        5）不少于10年相当于学位程度或专家水平的工作经验，当中最少5年担任高级职位(40分)
                    </div>
                    <div class="overs">
                        6）如拥有不少于2年相当于学位程度或专家水平及在你原居国家/地区以外地方的国际工作经验，可额外获取分数(15分)
                    </div>
                    <div class="overs">
                        7）如拥有不少于3年相当于学位程度或专家水平的跨国公司或知名企业的工作经验，如上市公司或位列《福布斯》全球企业2000强、《财富》世界500強和胡润中国500强企业，可额外获取分数(20分)
                    </div>
                </div>
                <div class="w15">
                    <div class="work">{{data.work_experience_score_a.result}}</div>
                    <div class="work3">{{data.work_experience_score_b.result}}</div>
                    <div class="work3 border-top">{{ data.work_experience_score_c.result }}</div>
                </div>
                <div class="w15 ite3">
                    <div class="work2">{{data.work_experience_score_a.score}}分</div>
                    <div class="work4">{{data.work_experience_score_b.score}}分</div>
                    <div class="work4 border-top">{{ data.work_experience_score_c.score }}分</div>
                </div>
            </div>
            <div class="item">
                <div class="tit">人才清单(最高30分)</div>
            </div>
            <div class="item answer">
                <div class="w70 qd">
                    <div>1）如符合人才清单内相关专业的规格，可额外获取分数</div>
                </div>
                <div class="w15 qd2">{{data.talent_list_score.result}}</div>
                <div class="w15 qd3">{{data.talent_list_score.score}}分</div>
            </div>
            <div class="item">
                <div class="tit">语言能力(最高20分)</div>
            </div>
            <div class="item answer">
                <div class="w70 ite1">
                    <div>
                        1）中文及英文 ——
                        国外大学(英语国家)毕业，英文授课，获得学位证。(如为中外联合办学，在中国上课的，不算)(20分)
                    </div>
                    <div>
                        2）中文及英文 ——
                        有近两年的雅思或托福成绩单(雅思考试G类或A类总分达6分；托福笔试达550分，计算机达213分，网考达80分)
                        (20分)
                    </div>
                    <div>
                        3）中文及另一种外语 ——
                        非英文的外语，如：法文、日文等(需提供相关证书)(15分)
                    </div>
                    <div>4）仅中文(10分)</div>
                    <div>5）仅英文(10分)</div>
                </div>
                <div class="w15 language">{{data.language_score.result}}</div>
                <div class="w15 language2">{{data.language_score.score}}分</div>
            </div>
            <div class="item">
                <div class="tit">家庭背景(最高20分)</div>
            </div>
            <div class="item answer">
                <div class="w70 ite1">
                    <div>
                        1）至少一名直系家庭（已婚配偶、父母、兄弟姊妹、子女）是现居于澳门的澳门永久性居民(5分)
                    </div>
                    <div>
                        2）随行已婚配偶的学历相当于大学学位或以上的水平(5分)
                    </div>
                    <div>
                        3）每名随行的18岁以下未婚及受养的子女得5分，最高得10分(10分)
                    </div>
                </div>
                <div class="w15 bg2">
                    <div class="bor-bot">{{data.background_score_a.result}}</div>
                    <div class="bor-bot">{{data.background_score_b.result}}</div>
                    <div>{{data.background_score_c.result}}</div>
                </div>
                <div class="w15 bg3">
                    <div class="bor-bot">{{data.background_score_a.score}}分</div>
                    <div class="bor-bot">{{data.background_score_b.score}}分</div>
                    <div>{{data.background_score_c.score}}分</div>
                </div>
            </div>
            <div class="sum">
                综合得分：{{data.total_score.score}}分
            </div>
        </div>
    </div>
</template>

<script>
import { getAssessment } from '../../../api/gifted'
export default {
    data() {
        return {
            data: {},
            isLoading: false,
        };
    },
    mounted() {
        this.getAssessment();
    },
    methods: {
        async getAssessment() {
            this.isLoading = true
            try {
                const { code, data } = await getAssessment({order_id: Number(this.$route.params.id)})
                if (code === 200) {
                    this.data = data
                }
            } finally {
                 this.isLoading = false
            }
        },
    }
};
</script>

<style lang="less" scoped>
.con {
    width: 100%;
    border: 1px solid #e4e4e4;
    font-size: 14px;
    line-height: 36px;
    .item {
        display: flex;
    }
    .overs {
        line-height: 24px;
        padding: 4px 0;
    }
    .head {
        div {
            border-right: 1px solid #e4e4e4;
        }
        div:nth-child(1) {
            width: 76%;
            padding-left: 15px;
        }
        div:nth-child(2) {
            width: 12%;
            text-align: center;
        }
        div:nth-child(3) {
            width: 12%;
            border-right: none;
            text-align: center;
        }
    }
    .tit {
        width: 100%;
        padding: 3px 15px;
        border-top: 1px solid #e4e4e4;
        border-bottom: 1px solid #e4e4e4;
        background: #e7f6ff;
    }
    .w70 {
        width: 76%;
    }
    .w15 {
        width: 12%;
    }
    .answer {
        .ite1 {
            div {
                border-bottom: 1px solid #e4e4e4;
                border-right: 1px solid #e4e4e4;
                padding-left: 30px;
            }
            div:last-child {
                border-bottom: none;
            }
        }
        .ite2 {
            border-right: 1px solid #e4e4e4;
            line-height: 130px;
            text-align: center;
        }
        .ite3 {
            line-height: 130px;
            text-align: center;
        }
        .edca,
        .edca-num1 {
            line-height: 130px;
            border-bottom: 1px solid #e4e4e4;
            text-align: center;
        }
        .edca-num1 {
            border-left: 1px solid #e4e4e4;
        }
        .edca2,
        .edca-num2 {
            line-height: 36px;
            text-align: center;
        }
        .edca-num2 {
            border-left: 1px solid #e4e4e4;
        }
        .work {
            line-height: 184px;
            border-bottom: 1px solid #e4e4e4;
            text-align: center;
        }
        .work2 {
            line-height: 184px;
            border-bottom: 1px solid #e4e4e4;
            text-align: center;
            border-left: 1px solid #e4e4e4;
        }
        .work3 {
            line-height: 56px;
            text-align: center;
        }
        .work4 {
            line-height: 56px;
            text-align: center;
            border-left: 1px solid #e4e4e4;
        }
        .qd {
            line-height: 36px;
            padding-left: 30px;
            border-right: 1px solid #e4e4e4;
        }
        .qd2 {
            line-height: 36px;
            border-right: 1px solid #e4e4e4;
            text-align: center;
        }
        .qd3 {
            line-height: 36px;
            text-align: center;
        }
        .language {
            line-height: 250px;
            text-align: center;
        }
        .language2 {
            line-height: 250px;
            text-align: center;
            border-left: 1px solid #e4e4e4;
        }
        .bg2 {
            text-align: center;
            line-height: 36px;
        }
        .bg3 {
            text-align: center;
            line-height: 36px;
            border-left: 1px solid #e4e4e4;
        }
        .bor-bot {
            border-bottom: 1px solid #e4e4e4;
        }
        .border-top {
            border-top: 1px solid #e4e4e4;
        }
    }
    .sum {
        border-top: 1px solid #e4e4e4;
        line-height: 80px;
        padding-left: 15px;
        background: #e7f6ff;
    }
}
</style>
