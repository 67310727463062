<!--
 * @Author: filename
 * @Description: 家庭成员-配偶信息
-->

<template>
    <Container :title="`配偶 - ${data.username} - ${data.follow == 1 ? `随行` : '不随行'}`" padding="15px">
        <el-row>
            <el-col :span="12">
                <span>配偶名称：</span>
                <span>{{ data.username }}</span>
            </el-col>
            <el-col :span="12">
                <span>曾用名：</span>
                <span>{{ data.used_name }}</span>
            </el-col>
            <el-col :span="12">
                <span>婚前姓氏：</span>
                <span>{{ data.surname || '-' }}</span>
            </el-col>
            <el-col :span="12">
                <span>姓名拼音：</span>
                <span>{{ data.username_pinyin }}</span>
            </el-col>
            <el-col :span="12">
                <span>性别：</span>
                <span>{{ data.sex }}</span>
            </el-col>
            <el-col :span="12">
                <span>出生日期：</span>
                <span>{{ data.birthday }}</span>
            </el-col>
            <el-col :span="12">
                <span>出生城市：</span>
                <span>{{ data.birth_place }}</span>
            </el-col>
            <el-col :span="12">
                <span>是否在职：</span>
                <span>{{ data.working_state }}</span>
            </el-col>
            <el-col :span="12">
                <span>职位名称：</span>
                <span>{{ data.profession }}</span>
            </el-col>
            <template v-if="data.follow == 1">
                <el-col :span="12">
                    <span>国籍/地区：</span>
                    <span>{{ data.nationality }}</span>
                </el-col>
                <el-col :span="24">
                    <el-divider />
                </el-col>
                <el-col :span="12">
                    <span>是否有学位：</span>
                    <span class="red">{{ data.degree.has }}</span>
                </el-col>
                <el-col :span="12" style="align-items: normal;">
                    <span>最高学位：</span>
                    <span>
                        <span style="display: block;" v-for="(item, i) in data.degree.qualifications" :key="i">{{item}}</span>
                    </span>
                </el-col>
                <el-col :span="24">
                    <el-divider />
                </el-col>
                <el-col :span="24">
                    <span>是否与主申请人同住：</span>
                    <span class="red">{{ data.live_state.with_applicant }}</span>
                </el-col>
                <el-col :span="inChina ? 24 : 12" style="align-items: normal;" v-if="data.live_state.with_applicant == '否'">
                    <span>现居住地址：</span>
                    <span>{{ inChina ? `${data.address.country}-${data.address.area[0] + '-' + data.address.area[1]}-${data.address.details}` : data.address.country + '-' + data.address.foreign}}</span>
                </el-col>
                <!-- <el-col :span="12" v-if="!inChina && data.live_state.with_applicant == '否'">
                    <span>是否在海外居住1年及以上: </span>
                    <span>{{ data.is_live_oversea_year === 1 ? '是' : '否' }}</span>
                </el-col> -->
                <el-col :span="24">
                    <el-divider />
                </el-col>
                <el-col :span="24">
                    <span>申请前十年是否曾居住其他国家/地区：</span>
                    <span class="red">{{ data.is_area_ten_years.state }}</span>
                </el-col>
                <el-col v-if="data.is_area_ten_years.state === '是'" :span="24" style="align-items: normal;">
                    <span>居住信息：</span>
                    <span>
                        <span style="display: block;" v-for="(item, i) in data.is_area_ten_years.subset_residence" :key="i">
                            {{item.country}}-{{item.city}}, 起止时间 {{ item.start_time }}~{{ item.end_time }}
                        </span>
                    </span>
                </el-col>
                <el-col :span="24">
                    <el-divider />
                </el-col>
                <el-col :span="24">
                    <span>是否在澳：</span>
                    <span class="red">{{ data.allow_in_hk.state }}</span>
                </el-col>
                <template v-if="data.allow_in_hk.state == '是'">
                    <el-col :span="12">
                        <span>获准逗留至：</span>
                        <span>{{ data.allow_in_hk.allow_limit_stay_at }}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>在澳身份：</span>
                        <span>{{ data.allow_in_hk.identity }}</span>
                    </el-col>
                </template>
            </template>
            <el-col :span="24">
                <el-divider />
            </el-col>
            <el-col :span="12">
                <span>是否办理过澳门身份证：</span>
                <span class="red">{{ data.hk_id.has }}</span>
            </el-col>
            <el-col :span="12" v-if="data.hk_id.has == '是'">
                <span>澳门身份证：</span>
                <span>{{ data.hk_id.number || '--' }}</span>
            </el-col>
        </el-row>
        <template v-if="data.follow == 1">
            <el-row v-for="(item, i) in data.certificates" :key="i">
                <el-col :span="24">
                    <el-divider />
                </el-col>
                <el-col :span="24">
                    <span>证件类别：</span>
                    <span>{{ item.type }}</span>
                </el-col>
                <el-col :span="12">
                    <span>证件号码：</span>
                    <span>{{ item.number }}</span>
                </el-col>
                <el-col :span="12">
                    <span>签发地：</span>
                    <span>{{ item.issue_at }}</span>
                </el-col>
                <el-col :span="12">
                    <span>签发日期：</span>
                    <span>{{ item.issue_date_start_at }}</span>
                </el-col>
                <el-col :span="12">
                    <span>届满日期：</span>
                    <span>{{ item.issue_date_end_at }}</span>
                </el-col>
            </el-row>
        </template>
    </Container>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {

        }
    },
    computed: {
        inChina() {
            return this.data.live_state.address.includes('中国')
        }
    }
}
</script>

<style lang="less" scoped>
.red {
    color: red;
}
</style>
